/* src/ProjectCard.css */
.card {
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 16px;
    max-width: 400px;
    margin: auto;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .title {
    font-size: 1.5em;
    margin-bottom: 0.5em;
  }
  
  .description {
    font-size: 1em;
    margin-bottom: 1em;
    color: #555;
  }
  
  .tags {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 8px;
    margin-bottom: 1em;
  }
  
  .tag {
    background-color: #f0f0f0;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 0.875em;
    color: #333;
  }
  
  .image-placeholder {
    background-color: #f9f9f9;
    border: 1px dashed #ddd;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
  }
  
  .image-placeholder img {
    max-width: 100%;
    max-height: 100%;
  }
  